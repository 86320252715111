<template>
  <transition name="fade" mode="out-in">
    <div
      id="sleeping"
      :class="
        settings.graphics && settings.graphics.mode == 'low'
          ? 'low_graphics'
          : null
      "
    >
      <v-app id="sleeping-app">
        <v-main>
          <v-container
            style="max-width: 480px; padding: 0; height: 100%"
            class="d-flex justify-end align-center pb-5 flex-column"
          >
            <div
              class="px-5 text-center py-3 rounded-xl mb-5 mx-3"
              style="background-color: rgba(255, 255, 255, 0.8)"
            >
              {{$t('view.PageSleeping.message')}}
            </div>
            <v-btn
              rounded
              color="yellow"
              x-large
              style="text-transform: capitalize"
              class="text-h5"
              @click="$router.push({ name: 'PageExploreLanding' })"
            >
              <h2>↻</h2>
              {{$t('action.refresh')}}
            </v-btn>
            <div class="d-flex mt-3">
              <div v-if="hours" class="mx-2">{{ hours }}h</div>
              :
              <div v-if="minutes" class="mx-2">{{ minutes }}m</div>
              :
              <div v-if="seconds" class="mx-2">{{ seconds }}s</div>
            </div>
          </v-container>
        </v-main>
        <DebugTool v-if="settings.debug"></DebugTool>
      </v-app>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  components: {
    DebugTool: () => import(
    /* webpackChunkName: "component-debug-tool" */ "@/components/DebugTool"),
  },
  data: () => ({
    hours: null,
    minutes: null,
    seconds: null,
  }),
  created() {
  },
  mounted() {
    this.tick();
    this.timer = setInterval(this.tick.bind(this), 1000);
  },
  methods: {
    updateRemaining(distance) {
      this.hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    },
    tick() {
      const distance = this.$moment("07:00:00", "hh:mm:ss")
        .add(1, "days")
        .diff(this.$moment());
      this.updateRemaining(distance);

      if (distance === 0) {
        clearInterval(this.timer);
      }
    },
  },
  destroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
@media (max-height: 500px) {
  #sleeping {
    background-size: auto 500px !important; /* Force the image to its minimum width */
  }
}

#sleeping {
  background: url("../../assets/background/Sleeping.png") center top;
  background-size: cover !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#sleeping-app {
  background: transparent !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.low_graphics {
  background: none !important;
}
</style>